import { Flex, IconWithBackground, Text } from '@strike-apps/shared/ui'

interface InfoSectionWithTitleProps {
  icon: React.FC
  iconColor?: 'facePrimary' | 'faceNegative'
  title: string
  children: React.ReactNode
}

export function InfoSectionWithTitle({
  icon,
  iconColor = 'facePrimary',
  title,
  children,
}: InfoSectionWithTitleProps) {
  return (
    <Flex
      flexDir="column"
      borderRadius="12px"
      borderWidth="1px"
      borderColor="borderPrimary"
      px={6}
      pt={6}
    >
      <Flex align="center" gap={4} mb={6}>
        <IconWithBackground icon={icon} color={iconColor} />
        <Text variant="subheadline2" color="facePrimary">
          {title}
        </Text>
      </Flex>
      {children}
    </Flex>
  )
}
