/* eslint-disable @typescript-eslint/no-namespace */
import { AlertIcon } from '@strike-apps/shared/icons'
import { Box, Flex, Text } from '@strike-apps/shared/ui'
import type { RelevantParams } from 'informed'
import {
  Form as BaseForm,
  Relevant,
  useFormState,
  type FormProps,
  type InformedProps,
} from 'informed'
import { useState } from 'react'

declare module 'informed' {
  namespace Multistep {
    function Step({
      children,
      step,
      relevant,
    }: {
      children: React.ReactNode
      step: string
      relevant: (relevantParams: RelevantParams) => boolean
    }): JSX.Element
  }
}

export interface UseFormValidationHook {
  wasSubmitted: boolean
  validateOnBlur: 'blur' | 'submit'
  validateOnChange: 'change' | 'submit'
  onSubmitClick: () => void
}

export const useFormValidation = (): UseFormValidationHook => {
  const [wasSubmitted, setWasSubmitted] = useState(false)

  return {
    wasSubmitted,
    validateOnBlur: wasSubmitted ? 'blur' : 'submit',
    validateOnChange: wasSubmitted ? 'change' : 'submit',
    onSubmitClick: () => setWasSubmitted(true),
  }
}

export const FormValidationMessages = () => {
  const state = useFormState()
  const errors: unknown[] = Object.values(state.errors)

  return (
    <Relevant when={({ formState }) => formState.invalid}>
      <Flex flexDirection="column" p="12px 16px" gap={2} bg="objectNegativeDisabled">
        {errors.map((error: string | unknown, i) => (
          <Flex alignItems="center" key={i}>
            <Box color="faceNegative">
              <AlertIcon />
            </Box>
            <Text variant="subheadline2" ml="6px">
              {error as string}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Relevant>
  )
}

export const Form = (props: InformedProps<FormProps>) => {
  return (
    <BaseForm style={{ display: 'contents' }} {...props}>
      {props.children}
    </BaseForm>
  )
}
