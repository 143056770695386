import { Icon, IconButton } from '@chakra-ui/react'
import { CopyIcon } from '@strike-apps/shared/icons'
import type { IconButtonProps } from '../forms/Button'
import { useCopy } from '../../hooks'

interface CopyIconButtonProps extends IconButtonProps {
  value: string
  successMessage: string
  iconSize?: number
}

export const CopyIconButton = ({
  value,
  successMessage,
  iconSize = 4,
  ...rest
}: CopyIconButtonProps) => {
  const handleCopy = useCopy(value, successMessage)

  return (
    <IconButton
      bg="transparent"
      icon={<Icon as={CopyIcon} boxSize={iconSize} />}
      variant="mono2"
      onClick={handleCopy}
      size="cd"
      {...rest}
    />
  )
}
