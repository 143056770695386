import type { TextElement, TextElementValue } from '@ln-zap/consumer-api'
import { useFormatCurrency } from '@strike-apps/commerce-dashboard/hooks'
import type { Currency } from '@strike-apps/strike/api-client'
import { Link } from '@strike-apps/shared/ui'
import format from 'date-fns/format'
import BigNumber from 'bignumber.js'

interface FormattedTextElementProps {
  /**
   * The text element to format.
   * @example
   * {
   *   "text": "This will replace your previous monthly {amount} bitcoin purchase.",
   *   "values": {
   *     "amount": {
   *       "amount": "1.00",
   *       "currency": "USD"
   *     }
   *   }
   * }
   */
  textElement: TextElement
}

/**
 * This component is used to display UI elements that are driven by the backend.
 * The backend provides the text and values to be displayed using objects with type TextElement.
 */
export function FormattedTextElement({ textElement }: FormattedTextElementProps) {
  const formatCurrency = useFormatCurrency()
  const tokens = textElement.text.split(/({\w+}|\n)/g).filter((token) => token !== '')
  const formatValue = (value: TextElementValue, key: number) => {
    if (value.amount && value.currency) {
      return formatCurrency(value.amount, value.currency as Currency).displayAmount
    }

    if (value.text) {
      return value.text
    }

    if (value.dateTime) {
      return new Date(value.dateTime).toLocaleString()
    }

    if (value.formattedDate && value.formattedDate.timestamp && value.formattedDate.formatPattern) {
      return format(
        new Date(value.formattedDate.timestamp),
        value.formattedDate.formatPattern.replace(/\btt\b/g, 'a'),
      )
    }

    if (value.percent) {
      return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(BigNumber(value.percent).dividedBy(100).toNumber())
    }

    if (value.url) {
      return (
        <Link key={key} href={value.url.url} isExternal>
          {value.url.text}
        </Link>
      )
    }

    return ''
  }

  return (
    <>
      {tokens.map((token, index) => {
        if (token.startsWith('{') && token.endsWith('}')) {
          const key = token.slice(1, -1)
          const value = textElement.values[key]

          return value ? formatValue(value, index) : null
        }

        if (token === '\n') {
          return <br key={index} />
        }

        return token
      })}
    </>
  )
}
