import { FormControl, RadioGroupInput, type RadioGroupOption } from '@strike-apps/shared/ui'
import { useField } from 'informed'
import type { BaseFormFieldProps } from './BaseFormField'

export interface FormRadioGroupProps extends BaseFormFieldProps {
  options: Record<string, RadioGroupOption>
}

export const FormRadioGroup = ({ label, options, warning, ...rest }: FormRadioGroupProps) => {
  const { fieldState, render, fieldApi, informed } = useField<unknown, string>({
    type: 'radio',
    ...rest,
  })
  const { error, showError } = fieldState
  const { onChange: _onChange, ...informedRest } = informed

  return render(
    <FormControl
      label={label}
      isInvalid={showError}
      hasWarning={Boolean(warning)}
      message={(error as string) ?? warning}
      isRequired={Boolean(rest.required)}
    >
      <RadioGroupInput
        name={rest.name}
        options={options}
        onChange={(newValue) => fieldApi.setValue(newValue)}
        {...informedRest}
      />
    </FormControl>,
  )
}
