import { CopyIconButton, Flex, Text } from '@strike-apps/shared/ui'

export function LightningAddress({ value }: { value: string }) {
  return (
    <Flex align="center" justify="space-between">
      <Flex direction="column" py={6}>
        <Text variant="subheadline2" color="facePrimary">
          Lightning address
        </Text>
        <Text variant="subheadline2" color="faceTertiary">
          {value}
        </Text>
      </Flex>
      <CopyIconButton
        value={value}
        successMessage={`Lightning address copied`}
        aria-label={`Copy Lightning address to clipboard`}
        color="facePrimary"
        bg="layerPrimary"
        iconSize={5}
        size="hug"
        p={2}
      />
    </Flex>
  )
}
