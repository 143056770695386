import { useField, type FieldProps } from 'informed'
import { useEffect } from 'react'
import { Dropdown, type DropdownProps } from '../Dropdown'

export interface InformedDropdownFieldProps extends DropdownProps {
  field: string
  defaultValue?: string
  informed?: Omit<FieldProps<unknown>, 'name'>
  onSelect?: (opt: string) => void
}

export function InformedDropdownField({
  field,
  defaultValue,
  isInvalid,
  onSelect,
  ...props
}: InformedDropdownFieldProps) {
  const { fieldApi, fieldState } = useField({ name: field, ...props.informed })
  const { getValue, setValue } = fieldApi
  const { error } = fieldState
  function handleSelect(opt: string) {
    setValue(opt)
    if (onSelect) {
      onSelect(opt)
    }
  }

  useEffect(() => {
    if (defaultValue && !getValue()) {
      setValue(defaultValue)
    }
  }, [getValue, setValue, defaultValue])

  return (
    <Dropdown
      id={field}
      isInvalid={Boolean(error) || isInvalid}
      invalidMessage={error as string}
      defaultValue={defaultValue}
      {...props}
      onSelect={handleSelect}
    />
  )
}
