import { useUserInfo } from '@strike-apps/commerce-dashboard/hooks'
import { Divider } from '@strike-apps/shared/ui'
import { Email } from './Email'
import { LightningAddress } from './LightningAddress'
import { Username } from './Username'

export function ProfileSectionContent() {
  const { data: userInfo } = useUserInfo()
  const username = userInfo?.username ?? ''
  const email = userInfo?.email ?? ''
  const lightningAddress = username ? `${username}@strike.me` : ''

  return (
    <>
      <Username value={username} />
      <Divider borderColor="borderPrimary" />
      <Email value={email} />
      <Divider borderColor="borderPrimary" />
      <LightningAddress value={lightningAddress} />
    </>
  )
}
