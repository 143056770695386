import { Flex, Text } from '@strike-apps/shared/ui'

export function Email({ value }: { value: string }) {
  return (
    <Flex direction="column" py={6}>
      <Text variant="subheadline2" color="facePrimary">
        Email
      </Text>
      <Text variant="subheadline2" color="faceTertiary">
        {value}
      </Text>
    </Flex>
  )
}
