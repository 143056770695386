import { useState } from 'react'

import type { SelectSize } from '../../../theme/components/select'
import type { ButtonProps } from '../../forms/Button'
import { Box } from '../../layout/Box'
import { Flex, type FlexProps } from '../../layout/Flex'
import { Icon } from '../../media-and-icons/Icon'
import { Text } from '../../typography/Text'
import { Warning } from '../../typography/Warning'
import { Select } from '../Select'

import { ChevronDownIcon } from '@strike-apps/shared/icons'

export interface DropdownProps extends Omit<ButtonProps, 'onSelect' | 'align'> {
  label: string
  placeholder: string
  message?: React.ReactNode
  options: DropdownItem[]
  defaultValue?: string
  onSelect?: (opt: string) => void
  isInvalid?: boolean
  invalidMessage?: string
  size?: SelectSize
  showLabel?: boolean
  renderOption?: (item: DropdownItem, index: number) => React.ReactElement
  renderSelected?: (item: DropdownItem) => React.ReactElement
}

export type DropdownItem = {
  key: string
  value: string
  disabled?: boolean
}

function getVariant(isDisabled: boolean, isCompact: boolean) {
  if (isDisabled) {
    return isCompact ? 'dropdownCompactDisabled' : 'dropdownDisabled'
  }
  if (isCompact) {
    return 'dropdownCompact'
  }
  return 'dropdown'
}

export function Dropdown({
  label,
  placeholder,
  options,
  defaultValue,
  message,
  isDisabled = false,
  onSelect,
  isInvalid,
  invalidMessage,
  size,
  showLabel,
  flexGrow,
  renderSelected,
  renderOption,
  sx,
  ...rest
}: DropdownProps & Pick<FlexProps, 'flexGrow'>) {
  const [selected, setSelected] = useState(defaultValue)
  const selectedOption = options.find((item) => item.key === selected)

  function selectOption(opt: string) {
    setSelected(opt)
    if (onSelect) {
      onSelect(opt)
    }
  }

  const messageComponent = isInvalid ? (
    <Warning isInvalid={isInvalid}>{invalidMessage}</Warning>
  ) : (
    message && <Warning isInfo>{message}</Warning>
  )

  const willShowLabel = !selected || showLabel

  const buttonTextVariant = size === 'sm' ? 'button2' : 'subheadline2'

  const hasCustomSelectedRenderer = typeof renderSelected === 'function'

  return (
    <Flex flexDir="column" gap={2} flexGrow={flexGrow}>
      <Select variant={getVariant(isDisabled, size === 'sm')} size={size}>
        <Select.Button
          rightIcon={<Icon boxSize={size === 'sm' ? 4 : 5} as={ChevronDownIcon} />}
          sx={{
            ...sx,
            borderColor: isInvalid ? 'borderNegative' : 'transparent',
          }}
          {...rest}
        >
          {selectedOption && hasCustomSelectedRenderer && renderSelected(selectedOption)}
          {(!selected || !hasCustomSelectedRenderer) && (
            <Flex alignItems="flex-start" flexDirection="column">
              {willShowLabel && <Text variant={buttonTextVariant}>{label}</Text>}
              {selected ? (
                <Text
                  variant={buttonTextVariant}
                  color={isDisabled ? 'facePrimaryDisabled' : 'facePrimary'}
                >
                  {selectedOption?.value ?? selectedOption?.key}
                </Text>
              ) : (
                <Text
                  variant={buttonTextVariant}
                  color="facePrimaryDisabled"
                  sx={{ _hover: { color: 'facePrimaryHover' } }}
                >
                  {placeholder}
                </Text>
              )}
            </Flex>
          )}
        </Select.Button>
        <Select.List minW={154}>
          {options.map((o, index) => (
            <Select.Option
              key={o.key}
              value={o.key}
              height="auto"
              onClick={() => selectOption(o.key)}
              isDisabled={o.disabled}
              isSelected={o.key === selected}
            >
              {typeof renderOption === 'function' && renderOption(o, index)}
              {!renderOption && o.value}
            </Select.Option>
          ))}
        </Select.List>
      </Select>
      {messageComponent && <Box ml={4}>{messageComponent}</Box>}
    </Flex>
  )
}
