import { Feature } from '@strike-apps/shared/feature-flags/react'
import { BitcoinSignIcon, LimitsIcon, LockIcon, UserIcon } from '@strike-apps/shared/icons'
import { Flex, Text } from '@strike-apps/shared/ui'
import { useSession } from 'next-auth/react'
import { Manage2FAButton } from './components'
import { BitcoinSectionContent } from './components/BitcoinSectionContent'
import { Limits } from './components/limits'
import { use2FAStatus } from './hooks'
import { ProfileSectionContent } from './components/ProfileSectionContent'
import { InfoSectionWithTitle } from '@strike-apps/commerce-dashboard/ui'

export function SettingsPageContent({ country }: { country: string | undefined }) {
  const { data } = useSession()
  const { data: mfaStatus } = use2FAStatus()
  const isBusinessUser = data?.user.business
  return (
    <Flex direction="column" flex={1} gap={6}>
      <Feature featureKey="feature-enable-2fa">
        <InfoSectionWithTitle icon={LockIcon} title="Security">
          <Flex justify="space-between" align="center" my={6}>
            <Flex direction="column">
              <Text variant="subheadline2" color="facePrimary">
                Enable 2FA
              </Text>
              <Text variant="subheadline2" color="faceTertiary">
                Require a 2-factor authentication (2FA) code to access your account
              </Text>
            </Flex>

            <Manage2FAButton is2FAEnabled={mfaStatus?.enabled ?? false} />
          </Flex>
        </InfoSectionWithTitle>
      </Feature>
      <InfoSectionWithTitle icon={UserIcon} title="Profile">
        <ProfileSectionContent />
      </InfoSectionWithTitle>
      <InfoSectionWithTitle icon={BitcoinSignIcon} title="Bitcoin">
        <BitcoinSectionContent />
      </InfoSectionWithTitle>

      {country && isBusinessUser && (
        <InfoSectionWithTitle icon={LimitsIcon} title="Limits">
          <Limits country={country} />
        </InfoSectionWithTitle>
      )}
    </Flex>
  )
}
