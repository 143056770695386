import type {
  AccordionButtonProps,
  AccordionItemProps as BaseAccordionItemProps,
} from '@chakra-ui/react'
import {
  AccordionButton,
  AccordionPanel,
  AccordionItem as BaseAccordionItem,
  Flex,
} from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@strike-apps/shared/icons'
import type { ReactNode } from 'react'
import { Icon } from '../../media-and-icons/Icon'
import { Text } from '../../typography/Text'

export interface AccordionItemProps extends BaseAccordionItemProps {
  label: ReactNode | string
  children: ReactNode

  buttonProps?: Partial<AccordionButtonProps>
}

export const AccordionItem = ({ label, children, buttonProps, ...rest }: AccordionItemProps) => {
  return (
    <BaseAccordionItem {...rest}>
      {({ isExpanded }) => (
        <>
          <AccordionButton {...buttonProps}>
            {typeof label === 'string' ? (
              <Text variant="subheadline3" color="faceTertiary">
                {label}
              </Text>
            ) : (
              label
            )}
            <Flex
              boxSize={9}
              borderRadius="12px"
              bg="layerSecondary"
              justify="center"
              align="center"
              color="facePrimary"
            >
              <Icon boxSize={5} as={isExpanded ? ChevronUpIcon : ChevronDownIcon} />
            </Flex>
          </AccordionButton>
          <AccordionPanel p={0}>{children}</AccordionPanel>
        </>
      )}
    </BaseAccordionItem>
  )
}

export { Accordion } from '@chakra-ui/react'
