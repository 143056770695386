import { Currency } from '@strike-apps/strike/api-client'
import {
  IconSwitchBTC,
  IconSwitchEUR,
  IconSwitchGBP,
  IconSwitchUSD,
} from '@strike-apps/shared/icons'

export const useGetCurrencySwitchIcon = (currency: Currency) => {
  const CurrencySwitchIcons: Record<Currency, React.FC> = {
    [Currency.USD]: IconSwitchUSD,
    [Currency.USDT]: IconSwitchUSD,
    [Currency.EUR]: IconSwitchEUR,
    [Currency.GBP]: IconSwitchGBP,
    [Currency.BTC]: IconSwitchBTC,
  }
  return CurrencySwitchIcons[currency]
}
