import { InformedTextField, type TextFieldProps } from '@strike-apps/shared/ui'
import type { FormApi, FormState } from 'informed'
import Bugsnag from '@bugsnag/js'

import { StrikeFetchError } from '@strike-apps/strike/fetch'

export const USERNAME_MAX_LENGTH = 15
export const field = 'username'
export const defaultError = 'There was an error while saving. Please try again later.'

export const useUsernameFormSubmitHandler = (save: (username: string) => void) => {
  const onSubmit = async (formState: FormState, formApi: FormApi) => {
    try {
      await save(formState.values[field] as string)
    } catch (error) {
      if (error instanceof StrikeFetchError && error.data.code === 'USER_NAME_ALREADY_IN_USE') {
        formApi.setError(field, 'The username is already taken.')
      } else {
        formApi.setError(field, defaultError)
      }
    }
  }

  return { onSubmit }
}

interface UsernameFormFieldProps extends TextFieldProps {
  label?: string
  defaultValue: string
}

export function UsernameFormField({ label, defaultValue, ...rest }: UsernameFormFieldProps) {
  const validate = (value: unknown) => {
    if (typeof value !== 'string') {
      Bugsnag.notify(new Error(`username form field value is not a string as expected: ${value}`))
      return defaultError
    }

    if (value.length < 3 || value.length > 15) {
      return 'Usernames must be between 3-15 characters.'
    }

    if (!/^[a-zA-Z0-9_]+$/.test(value)) {
      return 'Please enter letters, numbers, and underscores only.'
    }

    return
  }

  return (
    <InformedTextField
      informed={{
        field,
        required: true,
        validate,
        defaultValue,
      }}
      label={label}
      maxLength={USERNAME_MAX_LENGTH}
      autoFocus
      {...rest}
    />
  )
}
