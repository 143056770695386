import { useSaveUserProfile } from '@strike-apps/commerce-dashboard/hooks'
import { Currency } from '@strike-apps/shared/utils'
import { SingleItemAccordion } from '@strike-apps/commerce-dashboard/ui'
import { RadioGroup } from './RadioGroup'
import { Footer } from './Footer'
import { Radio } from './Radio'

interface PassiveReceiveCurrency {
  defaultPassiveCurrency: Currency
  defaultFiatCurrency: Currency
}

export function PassiveReceiveCurrency({
  defaultPassiveCurrency,
  defaultFiatCurrency,
}: PassiveReceiveCurrency) {
  const { mutateAsync: saveUserProfile } = useSaveUserProfile()

  return (
    <SingleItemAccordion
      label="Receive currency"
      description={`Receive as ${defaultPassiveCurrency === Currency.BTC ? 'bitcoin' : 'cash'}`}
    >
      <RadioGroup
        onChange={(value: Currency) => saveUserProfile({ defaultPassiveCurrency: value })}
        value={defaultPassiveCurrency}
      >
        <Radio
          value={Currency.BTC}
          label="Bitcoin"
          description="Unrequested payments will be received as bitcoin"
        />
        <Radio
          value={defaultFiatCurrency}
          label="Cash"
          description="Unrequested payments will be received as cash"
        />
        <Footer>Any requests you initiate will still be received in the requested currency.</Footer>
      </RadioGroup>
    </SingleItemAccordion>
  )
}
