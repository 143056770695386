import { CloseIcon } from '@strike-apps/shared/icons'
import type { FC } from 'react'
import { Button, IconButton } from '../../forms/Button'
import type { FlexProps } from '../../layout/Flex'
import { Flex } from '../../layout/Flex'
import { Icon } from '../../media-and-icons/Icon'
import { Link } from '../../navigation/Link'
import { Text } from '../../typography/Text'
import { Box, GridItem } from '@chakra-ui/react'
import { noop } from 'lodash-es'

export interface InfoCardProps extends FlexProps {
  title: string
  icon: FC
  description: string
  link?: string
  mainColor?: string
  iconColor?: string
  onClose?: () => void
  linkLabel?: string
  linkAction?: () => void
}

export const InfoCard = ({
  icon,
  title,
  description,
  onClose = noop,
  link,
  iconColor = 'facePrimary',
  mainColor = 'facePrimary',
  linkLabel,
  linkAction,
  ...rest
}: InfoCardProps) => {
  const cardComponent = (
    <Flex
      flex={1}
      bg="layerPrimary"
      flexDir="column"
      borderRadius="16px"
      height="100%"
      p={4}
      justifyContent="space-between"
      gap={6}
      {...rest}
    >
      <Flex justifyContent="space-between">
        <Flex
          bg="layerSecondary"
          boxSize="32px"
          borderRadius="8px"
          justifyContent="center"
          alignItems="center"
        >
          <Icon as={icon} boxSize="16px" color={iconColor} />
        </Flex>
        {onClose && (
          <IconButton
            as={CloseIcon}
            aria-label="Close"
            variant="ghost"
            size="hug"
            width={5}
            height={5}
            onClick={onClose}
            _hover={{
              cursor: 'pointer',
            }}
          />
        )}
      </Flex>

      <Flex flexDir="column">
        <Text variant="subheadline2" color={mainColor}>
          {title}
        </Text>
        <Text variant="body3" color="faceSecondary">
          {description}
        </Text>
        {linkLabel && linkAction && (
          <Box mt={3}>
            <Button
              size="sm"
              onClick={() => {
                linkAction()
                onClose()
              }}
            >
              {linkLabel}
            </Button>
          </Box>
        )}
      </Flex>
    </Flex>
  )

  if (link) {
    return (
      <GridItem height="100%">
        <Link
          href={link}
          height="100%"
          flex={1}
          _hover={{ textDecoration: 'none' }}
          target="_blank"
        >
          {cardComponent}
        </Link>
      </GridItem>
    )
  }

  return cardComponent
}
