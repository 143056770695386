import type { InputProps as BaseInputProps } from '@chakra-ui/react'
import { type FC, useEffect, useRef } from 'react'

import { forwardRef, Input as BaseInput } from '@chakra-ui/react'

export type InputSize = 'lg' | 'md' | 'sm'
export type InputVariant = 'normal' | 'modal' | 'textField' | 'phoneTextFieldPart'

export interface InputProps extends BaseInputProps {
  variant?: InputVariant
  isInvalid?: boolean
  hasWarning?: boolean
  size?: InputSize
  willFocus?: boolean
}

export const Input: FC<InputProps> = forwardRef<InputProps, typeof BaseInput>(
  ({ children, hasWarning, variant, willFocus, ...rest }, ref) => {
    const defaultVariant = 'normal'
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      // this allows the input to be focused when input is rendered by user action such as a click
      setTimeout(() => {
        if (inputRef.current && willFocus) {
          inputRef.current.focus()
        }
      }, 0)
    }, [willFocus])

    return (
      <BaseInput
        variant={variant || defaultVariant}
        ref={ref || inputRef}
        role="textbox"
        {...rest}
        sx={getBorderColor({ isInvalid: rest.isInvalid, hasWarning })}
      >
        {children}
      </BaseInput>
    )
  },
)

export const getBorderColor = ({
  isInvalid,
  hasWarning,
}: Pick<InputProps, 'isInvalid' | 'hasWarning'>): object => {
  if (isInvalid) return { borderColor: 'borderNegative' }
  if (hasWarning) return { borderColor: 'borderWarn' }

  return {}
}
