import { useToast } from './useToast'
import { WrenchIcon } from '@strike-apps/shared/icons'

export const useGenericErrorToast = () => {
  const { showToast } = useToast('GENERIC_ERROR_TOAST')

  return () => {
    showToast({
      toastType: 'error',
      title: `An error occurred`,
      message: 'Please try again or contact support if the problem persists.',
      icon: WrenchIcon,
    })
  }
}
