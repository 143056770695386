import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const useIsPageTransitioning = () => {
  const router = useRouter()
  const [isTransitioning, setIsTransitioning] = useState(false)

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsTransitioning(true)
    }
    const handleRouteChangeComplete = () => {
      setIsTransitioning(false)
    }
    const handleRouteChangeError = () => {
      setIsTransitioning(false)
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [router.events])

  return isTransitioning
}
