import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'
import { textStyles } from '../foundations/textStyles'

const sizes = {
  sm: {
    button: {
      h: '44px',
      py: '12px',
      px: '16px',
      ...textStyles.button1,
    },
    option: {
      ...textStyles.button1,
    },
    icon: {
      ml: '4px',
      width: '12px',
    },
  },
  md: {
    button: {
      h: '88px',
      py: '10px',
      px: '16px',
      ...textStyles.body3,
    },
    option: {
      ...textStyles.body3,
    },
    icon: {
      ml: '8px',
      width: '16px',
    },
  },
  lg: {
    button: {
      h: '110px', // TODO: Sync with Joshua and find out what the exact values for this should be
      p: '16px',
      borderRadius: '16px',
      minWidth: '200px',
      ...textStyles.body2,
    },
    option: {
      ...textStyles.body2,
    },
    icon: {
      ml: '8px',
      width: '16px',
    },
  },
}

function dropdownStyles({ isDisabled, isCompact } = { isDisabled: false, isCompact: false }) {
  return {
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: isDisabled ? 'facePrimaryDisabled' : 'facePrimary',
      bg: isCompact ? 'transparent' : isDisabled ? 'layerSecondaryDisabled' : 'layerSecondary',
      borderRadius: '12px',
      h: isCompact ? 8 : 9,
      w: isCompact ? undefined : 9,
      _hover: {
        bg: isCompact ? 'transparent' : 'layerPrimaryHover',
      },
    },
    button: {
      pointerEvents: isDisabled ? 'none' : 'auto',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      width: '100%',
      color: isDisabled ? 'faceTertiaryDisabled' : 'faceTertiary',
      bg: 'transparent',
      px: 4,
      py: 3,
      borderRadius: '16px',
      border: '1px solid',
      borderColor: 'borderPrimary',

      _hover: {
        color: isDisabled ? 'faceTertiary' : 'facePrimaryHover',
        bg: isDisabled ? 'layerBackground' : 'layerBackgroundHover',
      },
    },
    option: {
      pb: 6,
      pt: 6,
      px: 4,
      bg: 'layerPrimary',
      color: 'facePrimary',
      ...textStyles.subheadline2,
      _focus: {
        bg: 'layerPrimary',
      },
      _hover: {
        bg: 'layerPrimaryHover',
      },
    },
  }
}

const variants = {
  quiet: {
    button: {
      bg: 'transparent',
      borderRadius: '12px',
      borderWidth: '1px',
      borderColor: 'objectPrimary',
      px: 3,
      py: '10px',
      fontWeight: 'bold',
    },
  },
  outline: {
    button: {
      border: '1px solid',
      borderColor: 'layerTertiarySurface',
    },
  },
  dropdown: dropdownStyles(),
  dropdownCompact: dropdownStyles({ isDisabled: false, isCompact: true }),
  dropdownCompactDisabled: dropdownStyles({ isDisabled: true, isCompact: true }),
  dropdownDisabled: dropdownStyles({ isDisabled: true, isCompact: false }),
}

export const selectStyles: ComponentMultiStyleConfig = {
  parts: ['select', 'button', 'list', 'option', 'icon'],
  baseStyle: {
    icon: {
      right: 0,
      color: 'facePrimary',
    },
    option: {
      color: 'facePrimary',
    },
  },

  sizes,
  variants,
}

export type SelectVariant = keyof typeof variants
export type SelectSize = keyof typeof sizes
