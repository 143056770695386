import { AccordionItemV2, AccordionV2, Flex, Text } from '@strike-apps/shared/ui'
import type { ReactNode } from 'react'

interface AccordionProps {
  label: ReactNode
  labelColor?: 'facePrimary' | 'faceNegative' | 'faceTertiary'
  description: ReactNode
  descriptionColor?: 'facePrimary' | 'faceTertiary'
  isOpen?: boolean
  children: ReactNode
  onClick?: () => void
}

export function SingleItemAccordion({
  label,
  labelColor = 'facePrimary',
  description,
  descriptionColor = 'faceTertiary',
  isOpen,
  children,
  onClick,
}: AccordionProps) {
  const getIndex = () => {
    if (isOpen === undefined) {
      return
    }

    return isOpen ? 0 : -1
  }

  return (
    <AccordionV2 allowToggle index={getIndex()} onClick={onClick}>
      <AccordionItemV2
        label={
          <Flex direction="column" textAlign="left">
            <Text variant="subheadline2" color={labelColor}>
              {label}
            </Text>
            <Text variant="subheadline2" color={descriptionColor}>
              {description}
            </Text>
          </Flex>
        }
        border={0}
        py={4}
      >
        {children}
      </AccordionItemV2>
    </AccordionV2>
  )
}
