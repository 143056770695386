import {
  useBalances,
  useBitcoinUnit,
  useLocale,
  useSaveBitcoinUnit,
} from '@strike-apps/commerce-dashboard/hooks'
import {
  BITCOIN_UNIT_BTC,
  BITCOIN_UNIT_SATS,
  Currency,
  formatCurrency,
  getCurrencySymbol,
} from '@strike-apps/shared/utils'
import { SingleItemAccordion } from '@strike-apps/commerce-dashboard/ui'
import { RadioGroup } from './RadioGroup'
import { Footer } from './Footer'
import { Radio } from './Radio'

export function BitcoinUnit() {
  const { data: bitcoinUnit } = useBitcoinUnit()
  const { mutateAsync: saveBitcoinUnit } = useSaveBitcoinUnit()
  const { btcBalance } = useBalances()
  const locale = useLocale()
  const balanceInBtc = btcBalance?.current
    ? formatCurrency(
        { amount: btcBalance.current, currency: Currency.BTC },
        locale,
        true,
        BITCOIN_UNIT_BTC,
      ).displayAmount
    : ''
  const balanceInSats = btcBalance?.current
    ? formatCurrency(
        { amount: btcBalance.current, currency: Currency.BTC },
        locale,
        true,
        BITCOIN_UNIT_SATS,
      ).displayAmount
    : ''
  const bitcoinSymbol = getCurrencySymbol(Currency.BTC, locale)
  const oneHundredMillionSatsAmount = { amount: '1', currency: Currency.BTC }
  const oneHundredMillionSats = formatCurrency(
    oneHundredMillionSatsAmount,
    locale,
    true,
    BITCOIN_UNIT_SATS,
  ).displayAmount

  return (
    <SingleItemAccordion
      label="Bitcoin unit"
      description={bitcoinUnit === BITCOIN_UNIT_BTC ? 'Bitcoin' : 'Satoshi'}
    >
      <RadioGroup onChange={saveBitcoinUnit} value={bitcoinUnit}>
        <Radio value={BITCOIN_UNIT_BTC} label="Bitcoin" description={balanceInBtc} />
        <Radio value={BITCOIN_UNIT_SATS} label="Satoshi" description={balanceInSats} />
        <Footer>
          {bitcoinSymbol}
          {oneHundredMillionSatsAmount.amount} = {oneHundredMillionSats}
        </Footer>
      </RadioGroup>
    </SingleItemAccordion>
  )
}
