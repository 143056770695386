import { Button, Flex, InformedForm, useDisclosure, useToast } from '@strike-apps/shared/ui'
import { SingleItemAccordion } from '@strike-apps/commerce-dashboard/ui'
import {
  UsernameFormField,
  useUsernameFormSubmitHandler,
} from '@strike-apps/commerce-dashboard/feature-forms'
import { useSaveUserProfile } from '@strike-apps/commerce-dashboard/hooks'
import { Box } from '@chakra-ui/react'

export function Username({ value }: { value: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { showToast } = useToast('SAVE_USERNAME_TOAST')
  const { mutateAsync: saveUserProfile, isLoading } = useSaveUserProfile()
  const save = async (username: string) => {
    if (value !== username) {
      await saveUserProfile({ username })
      showToast({
        toastType: 'success',
        title: `Username updated successfully`,
        message: `Your Lightning address has also been updated`,
      })
    }

    onClose()
  }
  const { onSubmit } = useUsernameFormSubmitHandler(save)

  return (
    <SingleItemAccordion
      label="Username"
      description={value}
      isOpen={isOpen}
      onClick={isOpen ? onClose : onOpen}
    >
      <InformedForm autoComplete="off" onSubmit={onSubmit} onClick={(e) => e.stopPropagation()}>
        <Flex flexDir="column" gap={4} mt={3}>
          <Box h="72px">
            {isOpen && <UsernameFormField defaultValue={value} h="72px" px={4} py={6} willFocus />}
          </Box>
          <Flex justifyContent="flex-end" gap={2} pb={2}>
            <Button size="sm" colorScheme="tertiary" onClick={onClose}>
              Cancel
            </Button>
            <Button size="sm" type="submit" isLoading={isLoading}>
              Save
            </Button>
          </Flex>
        </Flex>
      </InformedForm>
    </SingleItemAccordion>
  )
}
